import _ from 'lodash';

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function trimSpaces(value: string) {
  return value.replace(/\s+/g, ' ');
}
export const convertToCamelCase = (string: string) =>
  string.replace(
    /([a-z0-9])_([a-z0-9])/gi,
    (match: any, p1: any, p2: string) => `${p1}${p2.toUpperCase()}`,
  );

export const convertObjectPropsToCamelCase = (obj = {}) => {
  return _.mapKeys(obj, (v, k) => _.camelCase(k));
};

export const convertObjectPropsToSnakeCase = (obj = {}) => {
  return _.mapKeys(obj, (v, k) => _.snakeCase(k));
};

export function convertPropsToCamelCase(value: unknown): unknown {
  if (Array.isArray(value)) {
    return value.map(obj => convertPropsToCamelCase(obj));
  }
  if (typeof value === 'object' && value !== null) {
    const obj: { [index: string]: any } = convertObjectPropsToCamelCase(value);
    Object.keys(obj).forEach(key => {
      obj[key] = convertPropsToCamelCase(obj[key]);
    });
    return obj;
  }
  return value;
}

export function convertPropsToSnakeCase(
  value: Record<string, any>,
): Record<string, any> {
  if (Array.isArray(value)) {
    return value.map(obj => convertPropsToSnakeCase(obj));
  }
  if (typeof value === 'object' && value !== null) {
    const obj: { [index: string]: any } = convertObjectPropsToSnakeCase(value);
    Object.keys(obj).forEach(key => {
      obj[key] = convertPropsToSnakeCase(obj[key]);
    });
    return obj;
  }
  return value;
}

export function isNumericString(value: string) {
  return /^\d+$/.test(value);
}

export function replaceEmptyStringsWithNull(
  data: Record<string, any>,
): Record<string, any> | null {
  if (Array.isArray(data)) {
    return data.map(item => replaceEmptyStringsWithNull(item));
  }
  if (typeof data === 'object' && data !== null) {
    const newObj: Record<string, any> = {};
    for (const key in data) {
      if (Object.hasOwn(data, key)) {
        newObj[key] = replaceEmptyStringsWithNull(data[key]);
      }
    }
    return newObj;
  }
  if (data === '') {
    return null;
  }
  return data;
}

export function joinObjectEntries(filter: Record<string, unknown> | null) {
  if (!filter) {
    return '';
  }
  return Object.keys(filter).reduce((previousValue, currentValue) => {
    const joinBy = '&';
    const value = filter[currentValue as keyof typeof filter];
    if (value !== null && value !== '') {
      return previousValue.concat(
        `${joinBy}${currentValue}=${filter[currentValue as keyof typeof filter]}`,
      );
    }
    return previousValue;
  }, '');
}

export function b64DecodeUnicode(value: string) {
  return decodeURIComponent(
    atob(value)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
}

export function generateUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
